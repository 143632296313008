import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cursor-pointer border-b border-b-transparent hover:border-b-darkGrey transition duration-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_UiInputText = _resolveComponent("UiInputText")!
  const _component_AuthSubmitButton = _resolveComponent("AuthSubmitButton")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("form", {
    class: "max-w-[398px] max-h-md w-full pt-10 px-5 pb-8 bg-white rounded-lg",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)), ["prevent"]))
  }, [
    _createVNode(_component_Logo, { class: "mb-[30px]" }),
    _createVNode(_component_UiInputText, {
      modelValue: _ctx.form.email.value.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.email.value.value) = $event)),
      errorMessage: _ctx.form.email.errorMessage.value,
      label: _ctx.form.email.label,
      type: "email",
      autocomplete: "email",
      class: "mb-4"
    }, null, 8, ["modelValue", "errorMessage", "label"]),
    _createVNode(_component_UiInputText, {
      modelValue: _ctx.form.password.value.value,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.password.value.value) = $event)),
      errorMessage: _ctx.form.password.errorMessage.value,
      label: _ctx.form.password.label,
      type: "password",
      class: "mb-4"
    }, null, 8, ["modelValue", "errorMessage", "label"]),
    _createVNode(_component_AuthSubmitButton, {
      class: "mb-6",
      loading: _ctx.isSubmitting,
      disabled: !_ctx.isValid
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('LOGIN_FORM_SUBMIT_BUTTON')), 1)
      ]),
      _: 1
    }, 8, ["loading", "disabled"]),
    _createVNode(_component_router_link, {
      to: { name: 'ForgetPasswordView' },
      class: "text-darkGrey flex justify-center text-base text-center font-medium leading-[10px]"
    }, {
      default: _withCtx(() => [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.$t('LOGIN_FORM_FORGET_PASSWORD')), 1)
      ]),
      _: 1
    })
  ], 32))
}