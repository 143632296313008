import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiButton = _resolveComponent("UiButton")!

  return (_openBlock(), _createBlock(_component_UiButton, {
    class: "w-full py-4 bg-blue hover:bg-darkBlue text-white",
    type: "submit"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}