
import { defineComponent, reactive, ref } from 'vue'
import { AxiosError } from 'axios'
import AuthSubmitButton from '@/components/particles/AuthLayout/AuthSubmitButton.vue'
import Logo from '@/components/particles/common/Logo.vue'
import useStore from '@/store'
import { AUTH_ACTION_TYPES } from '@/store/auth/actions'
import {
  useField,
  useForm,
  useIsFormValid,
  useIsSubmitting,
} from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css'

export default defineComponent({
  components: {
    AuthSubmitButton,
    Logo,
  },
  emits: ['success'],
  setup() {
    const store = useStore()
    const { t } = useI18n()
    const router = useRouter()
    const { handleSubmit } = useForm({
      validationSchema: {
        email: 'required|email',
        password: 'required',
      },
    })

    const form = reactive({
      email: useField('email', '', {
        label: t('LOGIN_FORM_LOGIN_LABEL'),
        validateOnValueUpdate: true,
      }),
      password: useField('password', '', {
        label: t('LOGIN_FORM_PASSWORD_LABEL'),
        validateOnValueUpdate: true,
      }),
    })

    const onSubmit = handleSubmit(async ({ email, password }) => {
      const logIn = async () => {
        await store.dispatch(
          AUTH_ACTION_TYPES.LOGIN,
          { email, password }
        )
      }
      try {
        await logIn();
        router.push({ name: 'HomeView' })
      } catch (e) {
        const axiosErr = e as AxiosError
        const errorStatus = axiosErr.response?.status
        const data = axiosErr.response?.data
        createToast({
          title: 'Erreur ' + errorStatus,
          description: data?.message
        },
          {
            type: 'danger',
            position: 'bottom-right',
            showIcon: true,
          })
        // notification show
      }
    })

    return {
      onSubmit,
      form,
      isValid: useIsFormValid(),
      isSubmitting: useIsSubmitting(),
      isPasswordValid: ref(false),
    }
  },
})
